<style>

</style>
<script>
import {VclBulletList, VclCode, VclFacebook, VclList} from 'vue-content-loading';

import CopyableInput from "@/components/CopyableInput";

export default {
  props: ['cftools_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableInput,
    VclFacebook,
    VclCode,
    VclList,
    VclBulletList
  }
};
</script>

<template>
  <div class="row">
    <div class="col-lg-11">
      <!-- Content row one -->
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <VclFacebook :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclFacebook>
                </div>
                <div class="col-lg-4">
                  <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                </div>
                <div class="col-lg-4">
                  <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="card">
            <div class="card-body">
              <VclBulletList :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclBulletList>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card">
            <div class="card-body">
              <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4 mb-2"></VclList>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
